import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "../../config/config";
import AddOrUpdatePrayerLesson from "../../components/PrayerLesson/AddOrUpdatePrayerLesson";
import {
  delete_prayer_lesson_api,
  prayer_lessons_list_api,
} from "../../DAL/PrayerLesson/PrayerLesson";

export default function PrayerLesson() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [prayerLesson, setPrayerLesson] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_prayer_lessons_list = async () => {
    const result = await prayer_lessons_list_api();
    if (result.code === 200) {
      const data = result.prayer_lesson.map((prayer_lesson) => {
        return {
          ...prayer_lesson,
          table_avatar: {
            src: s3baseUrl + prayer_lesson.image,
            alt: prayer_lesson.name,
          },
        };
      });
      setPrayerLesson(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenImage = (row, index) => {
    window.open(s3baseUrl + row.image);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "title", label: "Title", alignRight: false },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
      className: "reduce-cell-padding cursor-pointer",
      handleClick: handleOpenImage,
    },
    { id: "order", label: "Order", alignRight: false },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_prayer_lesson_api(selectedObject._id);
    if (result.code === 200) {
      const data = prayerLesson.filter(
        (prayer_lesson) => prayer_lesson._id !== selectedObject._id
      );
      setPrayerLesson(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_prayer_lessons_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Prayer Lessons</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Prayer Lesson</button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={prayerLesson}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Prayer Lesson`}
        componentToPassDown={
          <AddOrUpdatePrayerLesson
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            prayerLesson={prayerLesson}
            setPrayerLesson={setPrayerLesson}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this lesson?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
