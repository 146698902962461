import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { leaves_request_list_api } from "../../DAL/LeaveRequests/LeaveRequests";

const TABLE_HEAD = [
  { id: "number", label: "#", type: "number" },
  { id: "student_name", label: "Student Name" },
  { id: "title", label: "Leave Title" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "description", label: "Description" },
  { id: "added_by", label: "Added By" },
];

export default function LeaveRequests() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [leavesList, setLeavesList] = useState([]);

  const get_leaves_list = async () => {
    setIsLoading(true);
    const result = await leaves_request_list_api();
    if (result.code === 200) {
      const data = result.leave_request.map((leave) => {
        let student_name = leave.student.name;
        let added_by = leave.action_info.name;
        let start_date = moment(leave.start_date).format("DD-MM-YYYY");
        let end_date = moment(leave.end_date).format("DD-MM-YYYY");
        return { ...leave, start_date, end_date, student_name, added_by };
      });
      setLeavesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_leaves_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Leaves List</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={leavesList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
