import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearAllIcon from "@mui/icons-material/ClearAll";

export default function StudentFilter({
  handleSubmit,
  filterData,
  handleChange,
  handleClearFilter,
}) {
  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Lesson Book
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Lesson Book"
                  name="lesson_book"
                  className="mui-select"
                  value={filterData.lesson_book}
                  onChange={(e) => {
                    handleChange("lesson_book", e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="quran">Holy Quran</MenuItem>
                  <MenuItem value="quaida">Noorani Quaida</MenuItem>
                  <MenuItem value="madani_quaida">Madani Quaida</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Gender"
                  name="gender"
                  className="mui-select"
                  value={filterData.gender}
                  onChange={(e) => {
                    handleChange("gender", e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Online Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Online Status"
                  name="is_online"
                  className="mui-select"
                  value={filterData.is_online}
                  onChange={(e) => {
                    handleChange("is_online", e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value={true}>Online</MenuItem>
                  <MenuItem value={false}>Offline</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={filterData.status}
                  onChange={(e) => {
                    handleChange("status", e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="add--button d-flex justify-content-between mt-3">
            <button onClick={handleClearFilter}>
              Clear&nbsp;&nbsp;
              <ClearAllIcon />
            </button>
            <button>
              Filter&nbsp;&nbsp;
              <FilterAltIcon />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
