import React from "react";
import { Icon } from "@iconify/react";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function CallStatus({ message }) {
  const { socket, userInfo } = useContentSetting();

  const handleEndCall = (message) => {
    let event = "call:ended";
    socket.emit(event, {
      to: "",
      from: userInfo._id,
      last_call_id: message._id,
    });
  };

  return (
    <div className="d-flex call_status_1 align-items-center">
      <div> {message.call_status_1} </div>
      {message.call_status === "started" && (
        <div
          className="success-call-box error"
          onClick={() => handleEndCall(message)}
        >
          <Icon
            fontSize="12"
            className="start-call-icon"
            icon="subway:call-3"
          />
        </div>
      )}
    </div>
  );
}
