import React from "react";
import { no_data_found_image } from "../../assets";
export default function RecordNotFound({ title }) {
  return (
    <div className="no-access-string">
      <img className="mx-auto" src={no_data_found_image} alt="RecordNotFound" />
      <p className="mt-3">{title}</p>
    </div>
  );
}
