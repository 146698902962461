import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";
import {
  add_announcements_api,
  update_announcements_api,
} from "../../DAL/Announcements/Announcements";

export default function AddOrUpdateTemplates({
  onCloseDrawer,
  setAnnouncementsData,
  formType,
  selectedObject,
  announcementsData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    title: "",
    description: "",
    users_type: "all",
    status: true,
    template_type: "general",
    is_template: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = {
      status: state.status,
      template_type: state.template_type,
      title: state.title,
      description: state.description,
      is_template: state.is_template,
      users_type: state.users_type,
    };

    const result =
      formType === "ADD"
        ? await add_announcements_api(formData)
        : await update_announcements_api(formData, state._id);
    if (result.code === 200) {
      let announcement = result.announcement;
      if (formType === "ADD") {
        setAnnouncementsData((announcementsData) => [
          announcement,
          ...announcementsData,
        ]);
      } else {
        const newState = announcementsData.map((obj) => {
          if (obj._id === state._id) {
            return announcement;
          }
          return obj;
        });
        setAnnouncementsData(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="template_type">Announcements Type</InputLabel>
                <Select
                  labelId="template_type"
                  id="template_type"
                  label="Announcements Type"
                  name="template_type"
                  className="mui-select"
                  value={state.template_type}
                  onChange={handleChange}
                >
                  <MenuItem value="general">General</MenuItem>
                  <MenuItem value="associate">Associate</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mt-3">
              <h6>Description *</h6>
              <GeneralCkeditor
                setInputs={setState}
                inputs={state}
                name="description"
                editorHeight={320}
              />
            </div>
            <div className="col-12 add--button text-end mt-3">
              <button disabled={isLoading}>
                {isLoading
                  ? formType === "ADD"
                    ? "Saving..."
                    : "Updating..."
                  : formType === "ADD"
                  ? "Save"
                  : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
