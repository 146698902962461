import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function ThemeConfig({ children }) {
  const themeOptions = {
    palette: {
      primary: {
        main: "#2a9988", // Update the primary color
      },
      secondary: {
        main: "rgba(22, 52, 60, 1)", // Update the secondary color
      },
      text: {
        primary: "#90a7ad",
      },
    },
  };
  const theme = createTheme(themeOptions);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
