import React, { useState, useEffect } from "react";
import { open_ai_api } from "../../DAL/Login/Login";

const YOUR_OPENAI_API_KEY =
  "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";

const App = () => {
  const [generatedText, setGeneratedText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const get_personal_notes_list = async () => {
    const result = await open_ai_api();
    if (result.code === 200) {
    } else {
    }
  };

  useEffect(() => {
    get_personal_notes_list();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Generated Text:</h1>
      <p>{generatedText}</p>
    </div>
  );
};

export default App;
