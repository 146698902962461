import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { delete_student_api } from "../../DAL/Students/Students";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import moment from "moment";
import { calls_list_api } from "../../DAL/Messages/Messages";
import CallsFilter from "../../components/Chatting/CallsFilter";
import { show_proper_words } from "../../utils/constant";
import CallParticipant from "../../components/Chatting/CallParticipant";
import CallStatus from "../../components/Chatting/CallStatus";

const EMPTY_FILTER = {
  call_status: "all",
  start_date: new Date(new Date().setDate(new Date().getDate() - 2)),
  end_date: new Date(new Date()),
  search_text: "",
};

const get_ended_text = (call) => {
  const { call_status, call_started_time, call_ended_time } = call;
  let ended_text = "00:00:00";
  if (call_status === "ended") {
    const startTime = moment(call_started_time);
    const endTime = moment(call_ended_time);
    const total_time = endTime.diff(startTime, "seconds");
    let hours = Math.floor(total_time / 3600);
    let minutes = Math.floor((total_time % 3600) / 60);
    let seconds = Math.floor((total_time % 3600) % 60);

    if (hours > 0) {
      ended_text = `${hours < 10 ? "0" + hours : hours}:`;
    } else {
      ended_text = `00:`;
    }
    if (minutes > 0) {
      ended_text += `${minutes < 10 ? "0" + minutes : minutes}:`;
    } else {
      ended_text += `00:`;
    }
    if (seconds > 0) {
      ended_text += `${seconds < 10 ? "0" + seconds : seconds}`;
    } else {
      ended_text += `00`;
    }
  }
  return ended_text;
};

export default function CallsList() {
  const { enqueueSnackbar } = useSnackbar();
  const { socket } = useContentSetting();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [callsList, setCallsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [filterData, setFilterData] = useState(EMPTY_FILTER);

  const handleOpenFilterDrawer = () => {
    setFilterDrawer(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const get_students_list = async (data) => {
    setIsLoading(true);
    let postData = { ...data };
    postData.start_date = moment(postData.start_date).format("YYYY-MM-DD");
    postData.end_date = moment(postData.end_date)
      .add(1, "days")
      .format("YYYY-MM-DD");
    const result = await calls_list_api(postData);
    if (result.code === 200) {
      const data = result.calls_list.map((call) => {
        const { call_status, call_started_time, createdAt, participants } =
          call;

        let joined_users = participants.filter(
          (p) => p.participant_info !== null
        );

        return {
          ...call,
          ended_text: get_ended_text(call),
          joined_users,
          created_at: moment(createdAt).format("DD-MM-YYYY hh:mm A"),
          started_time: moment(call_started_time).format("DD-MM-YYYY hh:mm A"),
          call_status_1: show_proper_words(call_status),
        };
      });
      setCallsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "call_status_1",
      label: "Call Status",
      renderData: (message) => <CallStatus message={message} />,
    },
    { id: "started_time", label: "Call Started Time" },
    { id: "ended_text", label: "Call Duration" },
    {
      id: "joined_users",
      label: "Joined Users",
      renderData: (message) => <CallParticipant message={message} />,
    },
    { id: "created_at", label: "Created At" },
  ];

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleChange = (name, value) => {
    setFilterData((old) => {
      return { ...old, [name]: value };
    });
  };

  const searchFunction = () => {
    handleCloseFilterDrawer();
    get_students_list(filterData);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilterData(EMPTY_FILTER);
    handleCloseFilterDrawer();
    get_students_list(EMPTY_FILTER);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_student_api(selectedObject._id);
    if (result.code === 200) {
      const data = callsList.filter(
        (student) => student._id !== selectedObject._id
      );
      setCallsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_message_receiver = (result) => {
    if (result.code === 200) {
      if (result.message.message_type === "call") {
        let call = result.message;
        let joined_user = result.joined_user;
        const { call_status, createdAt, participants } = call;
        let joined_users = [];
        if (joined_user) {
          joined_users = [
            { ...participants[0], participant_info: joined_user },
          ];
        }
        let callData = {
          ...call,
          ended_text: "00:00:00",
          joined_users,
          created_at: moment(createdAt).format("DD-MM-YYYY hh:mm A"),
          started_time: moment(createdAt).format("DD-MM-YYYY hh:mm A"),
          call_status_1: show_proper_words(call_status),
        };
        setCallsList((old) => [callData, ...old]);
      }
    }
  };

  const update_call_status = (values, status) => {
    const chat_message = values.message;
    const joined_user = values.joined_user;
    let last_call_id = chat_message._id;

    setCallsList((old) =>
      old.map((message) => {
        if (message._id === last_call_id) {
          const { participants } = chat_message;
          let joined_users = message.joined_users;
          if (status === "ended") {
            return {
              ...message,
              ended_text: get_ended_text(message),
              call_status: status,
              call_status_1: show_proper_words(status),
            };
          } else if (status === "started") {
            if (joined_user) {
              let participant = participants.find(
                (p) => p.member === joined_user._id
              );
              participant.participant_info = joined_user;
              joined_users = [...joined_users, participant];
            }
          }

          return {
            ...message,
            call_status: status,
            joined_users: joined_users,
            call_status_1: show_proper_words(status),
          };
        }
        return message;
      })
    );
  };

  const handleChangeMedia = async (data) => {
    const { media_type, media_status, last_call_id, from } = data;
    setCallsList((old) =>
      old.map((message) => {
        if (message._id === last_call_id) {
          message.joined_users?.map((participant) => {
            if (participant.member === from) {
              participant[media_type] = media_status;
            }
            return participant;
          });
        }
        return message;
      })
    );
  };

  useEffect(() => {
    get_students_list(EMPTY_FILTER);

    socket.on("send_message_from_class_receiver", (data) => {
      handle_message_receiver(data);
    });
    socket.on("call:ringing", (data) => {
      update_call_status(data, "ringing");
    });
    socket.on("call:accepted", (data) => {
      update_call_status(data, "started");
    });
    socket.on("call:canceled", (data) => {
      update_call_status(data, "canceled");
    });
    socket.on("call:declined", (data) => {
      update_call_status(data, "declined");
    });
    socket.on("call:ended", (data) => {
      update_call_status(data, "ended");
    });
    socket.on("call:no_answered", (data) => {
      update_call_status(data, "no_answered");
    });
    socket.on("call:unavailable", (data) => {
      update_call_status(data, "unavailable");
    });
    socket.on("permissions_error", (data) => {
      update_call_status(data, "permissions_error");
    });
    socket.on("changed_media", handleChangeMedia);

    return () => {
      socket.off("send_message_from_class_receiver");
      socket.off("call:ringing");
      socket.off("call:accepted");
      socket.off("call:declined");
      socket.off("call:canceled");
      socket.off("call:ended");
      socket.off("call:no_answered");
      socket.off("call:unavailable");
      socket.off("permissions_error");
      socket.off("changed_media");
    };
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Call List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button className="me-2" onClick={handleOpenFilterDrawer}>
                    Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                  </button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={callsList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Calls Filter"
        componentToPassDown={
          <CallsFilter
            filterData={filterData}
            handleChange={handleChange}
            handleSubmit={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this student?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
