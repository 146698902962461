import { invokeApi } from "../../bl_libs/invokeApi";

export const announcements_list_api = async (is_template) => {
  const requestObj = {
    path: `api/announcements/list/${is_template}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const announcements_detail_api = async (announcements_id) => {
  const requestObj = {
    path: `api/announcements/${announcements_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_announcement_users_api = async (announcements_id) => {
  const requestObj = {
    path: `api/announcements/get_announcement_users/${announcements_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_announcements_api = async (data) => {
  const requestObj = {
    path: `api/announcements`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_announcements_api = async (data, announcements_id) => {
  const requestObj = {
    path: `api/announcements/${announcements_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_announcements_api = async (announcements_id) => {
  const requestObj = {
    path: `api/announcements/${announcements_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const delete_announcements_user_api = async (
  announcements_id,
  user_id
) => {
  const requestObj = {
    path: `api/announcements/announcements_user/${announcements_id}/${user_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
