import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { user_devices_list_api } from "../../DAL/UserDevices/UserDevices";

export default function UserDevices({ user_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user_id } = useParams();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [studentsList, setStudentsList] = useState([]);
  const [actionInfo, setActionInfo] = useState({});

  const get_students_list = async () => {
    let postData = { user_type, action_id: user_id };
    const result = await user_devices_list_api(postData);
    if (result.code === 200) {
      const data = result.user_devices.map((device) => {
        let browser_name = "";
        let os_name = "";
        if (device.device_info?.browserName) {
          let { browserName, browserFullVersion, osName, osVersion } =
            device.device_info;

          browser_name = browserName + "(" + browserFullVersion + ")";
          os_name = osName + "(" + osVersion + ")";
        }

        return {
          ...device,
          ...device.device_info,
          browser_name,
          os_name,
          created_at: moment(device.createdAt).format("DD-MM-YYYY hh:mm A"),
        };
      });
      setActionInfo(result.user_info);
      setStudentsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "browser_name", label: "Browser" },
    { id: "os_name", label: "OS" },
    { id: "created_at", label: "Created At" },
    { id: "userAgent", label: "User Agent" },
  ];

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const navigate_to = `/${pathname.split("/")[1]}`;

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div className="back-button-box">
                  <Link to={navigate_to}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {user_type === "student"
                    ? actionInfo.name +
                      " (" +
                      actionInfo.roll_number_string +
                      ")"
                    : actionInfo.name + " (" + actionInfo.email + ")"}
                </div>
              </div>
              <div className="light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>User Devices</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={studentsList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
