import { invokeApi } from "../../bl_libs/invokeApi";

export const prayer_lessons_list_api = async () => {
  const requestObj = {
    path: `api/prayer_lesson`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_prayer_lesson_api = async (data) => {
  const requestObj = {
    path: `api/prayer_lesson`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_prayer_lesson_api = async (data, prayer_lesson_id) => {
  const requestObj = {
    path: `api/prayer_lesson/${prayer_lesson_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_prayer_lesson_api = async (prayer_lesson_id) => {
  const requestObj = {
    path: `api/prayer_lesson/${prayer_lesson_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
