import React from "react";
import { show_proper_words } from "../../utils/constant";
import { Icon } from "@iconify/react";

export default function CallParticipant({ message }) {
  return (
    <div className="d-flex">
      <div>
        {message.joined_users?.length > 0 &&
          message.joined_users.map((user) => {
            const {
              participant_info,
              participant_type,
              is_muted,
              is_screen_shared,
              is_paused,
            } = user;
            if (participant_info) {
              let participant_name =
                participant_info.name +
                " (" +
                show_proper_words(participant_type) +
                ")";

              return (
                <div className="d-flex">
                  <div>{participant_name}</div>
                  <div className="">
                    <Icon
                      fontSize="16"
                      className="start-call-icon mx-2"
                      icon={`${is_muted ? "vaadin:mute" : "bi:mic-fill"}`}
                    />
                    {participant_type === "student" ? (
                      <Icon
                        fontSize="18"
                        className="start-call-icon"
                        icon={`${
                          is_paused ? "mdi:camera-off" : "ic:round-camera-alt"
                        }`}
                      />
                    ) : (
                      <Icon
                        fontSize="18"
                        className="start-call-icon"
                        icon={`${
                          is_screen_shared
                            ? "fluent:share-screen-stop-28-filled"
                            : "fluent:share-screen-start-28-filled"
                        }`}
                      />
                    )}
                  </div>
                </div>
              );
            }
            return <></>;
          })}
      </div>
    </div>
  );
}
