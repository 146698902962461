//General Variables
const QURAN_PAGES_BASE_URL = "https://assets.rabbaniquranschool.com/quran/";
const QUAIDA_PAGES_BASE_URL = "https://assets.rabbaniquranschool.com/qauida/";

//ENV based Variables
const config = require("./config.json");
const APP_ENV = process.env.REACT_APP_ENV;
const envConfig = config[APP_ENV];

//exporting Variables
export const baseUrl = envConfig.API_BASE_URL;
export const s3baseUrl = envConfig.S3_BASE_URL;
export const studentBaseUrl = envConfig.STUDENT_BASE_URL;
export const associateBaseUrl = envConfig.ASSOCIATE_BASE_URL;
export const clientBaseUrl = envConfig.CLIENT_BASE_URL;
export const teacherBaseUrl = envConfig.TEACHER_BASE_URL;
export const quranPagesBaseUrl = QURAN_PAGES_BASE_URL;
export const quaidaPagesBaseUrl = QUAIDA_PAGES_BASE_URL;
export const websiteBaseUrl = envConfig.WEBSITE_BASE_URL;
