import { invokeApi } from "../../bl_libs/invokeApi";

export const associates_list_api = async (role) => {
  const requestObj = {
    path: `api/associates/${role}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_associate_api = async (data) => {
  const requestObj = {
    path: `api/associates`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_associate_password_api = async (associate_id, data) => {
  const requestObj = {
    path: `api/associates/change_password/${associate_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_associate_api = async (data, associate_id) => {
  const requestObj = {
    path: `api/associates/${associate_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_associate_api = async (associate_id) => {
  const requestObj = {
    path: `api/associates/${associate_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const generate_associate_token_api = async (student_id) => {
  const requestObj = {
    path: `api/associates/generate_token/${student_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
