import { invokeApi } from "../../bl_libs/invokeApi";

export const leaves_request_list_api = async (type) => {
  const requestObj = {
    path: `api/leaves_request`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const leaves_request_by_student_api = async (user_id) => {
  const requestObj = {
    path: `api/leaves_request/leaves_by_student/${user_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
